import { Component, Input, OnInit } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalPaymentComponent } from '../../component/modal-payment/modal-payment.component';
import { ModalAddtaskComponent } from '../../component/modal-addtask/modal-addtask.component';
// 创建服务
import { ModalAddserviceComponent } from '../../component/modal-addservice/modal-addservice.component';
import { ModalEditProductComponent } from '../../component/modal-edit-product/modal-edit-product.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Api } from '@service/Api';
import { ModalOpenImageComponent } from '../modal-open-image/modal-open-image.component';
import { CreatePropertyDistributorsComponent } from '../create-property-distributors/create-property-distributors.component';
import { NzDrawerRef } from 'ng-zorro-antd';
import { ModalWangeditorComponent } from '../modal-wangeditor/modal-wangeditor.component';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DrawerTaskComponent } from '../../component/drawer-task/drawer-task.component';
import { Global } from '@service/global.ts';
import { Utils } from '@service/Utils';
import { DrawerRequesExamineComponent } from '../../component/drawer-reques-examine/drawer-reques-examine.component';
import { DrawerServiceStoragesComponent } from '../../component/drawer-service-storages/drawer-service-storages.component';
import { ModalOrderExamineComponent } from '../../component/modal-order-examine/modal-order-examine.component';
import { DrawerRequesExamineBackComponent } from '../../component/drawer-reques-examine-back/drawer-reques-examine-back.component';

let cnm;
let apiTmp;
@Component({
  selector: 'drawer-order',
  templateUrl: './drawer-order.component.html',
  styleUrls: ['./drawer-order.component.less']
})

export class DrawerOrderComponent implements OnInit {
  @Input() value = '';
  @Input() type: any;
  @Input() menuType: any; // my 我的订单  team 团队订单
  @Input() single: any; // my 我的订单  team 团队订单
  @Input() isRelevance: boolean;// 是否联合签单人  true 不能操作

  @Input() isMyPay: boolean;// 甩单 支付状态 
  @Input() payStatus: string;// 支付状态 
  @Input() priceStatus: number;// 价格状态 

  jobId: any;
  addTaskForm: FormGroup;
  editInoviceForm: FormGroup;
  redInoviceForm: FormGroup;
  expressage = false; // 寄送
  dedicated = false; // 专票
  orderInfo;    // 客户信息
  taskInfo = []; // 任务信息
  taskRecordList = []; // 任务跟进记录列表
  customerTaskRecord = [] // 交付记录
  priceDisabled = null;//订单状态，价格是否能修改 
  labelModal: NzModalRef;
  form1: FormGroup;
  formFk: FormGroup;
  formRenewType: FormGroup;
  formPN: FormGroup;
  formCost: FormGroup;
  formRz: FormGroup;
  formProductCompany: FormGroup;
  form2: FormGroup;
  form: FormGroup;
  form3: FormGroup;
  productInfo = [];  // 产品信息
  paymentList = [];  // 支付记录列表
  refundRecord = []; // 退款信息
  activeList = [0];  // 已激活菜单
  taskList = [];     // 任务列表
  contractDetail;    // 合同详情
  inoviceList = [];  // 发票列表
  uploadRecordUrl;   // 上传支付凭证url
  uploadInvoiceUrl;  // 上传发票url
  selectRefundIsAll: any = '0';
  companyData = []; //公司列表
  nzOptions = [];
  staffList: [];//关联员工列表
  orderRecordList = [];//订单跟进日志
  panel = {
    taskTitle: '任务详细信息',
    taskName: '详细信息',
    taskRecord1: '跟进记录',
    taskRecord2: '交付记录',
  }
  contractUrl: string;
  constructor(
    fb: FormBuilder,
    private drawerRef: NzDrawerRef<string>,
    private modalService: NzModalService,
    public message: NzMessageService,
    public api: Api,
    private drawerService: NzDrawerService,
    public global: Global,
  ) {
    apiTmp = this.api;
    this.uploadRecordUrl = this.api.uploadRecord();
    this.uploadInvoiceUrl = this.api.uploadInvoices();
    this.contractUrl = this.api.storagesUploadWithController();

    this.addTaskForm = fb.group({
      taskId: [null, Validators.required],
      period: [null, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
    });

    this.redInoviceForm = fb.group({
      remark: ["", Validators.required],
    });

    this.editInoviceForm = fb.group({
      invoiceContent: [null, Validators.required],
      invoiceTaxNo: [null],
      invoiceType: [null, Validators.required],
      vatCompanyAddress: [null],
      vatBankAccount: [null],
      mailing: [null, Validators.required],
      consigneeRealname: [null],
      consigneeTelphone: [null],
      consigneeAddress: [null]
    });

    this.form1 = fb.group({
      refundOtherReson: [null],
      refundIsAll: [null, Validators.required],
      receivingSide: [null, Validators.required],
      receivingAccount: [null, Validators.required],
      receivingBank: [null, Validators.required],
      remark: [null],
      refundsMethodId: [null],
      refundCount: [0, Validators.required],
    });

    this.formFk = fb.group({
      paymentCompany: [null, Validators.required],
      paymentTime: [null, Validators.required],
      bankFlowNo: [null, Validators.required],
    });

    this.formPN = fb.group({
      productNature: [null, Validators.required],
    });
    this.formCost = fb.group({
      cost: [null, Validators.required],
    });
    this.formRz = fb.group({
      bussCompanyId: [null, Validators.required],
      bussCompanyName: [null],
    });
    this.formProductCompany = fb.group({
      companyId: [null, Validators.required],
    });

    this.form2 = fb.group({
      status: [0, Validators.required],
      totalPayment: [0, [Validators.required, Validators.pattern(/^[0-9]*$/)]],
      paymentAmount: [0, Validators.required],
      remark: [null],
      orderId: [null],
    });
    this.form = fb.group({
      name: [null, Validators.required],
      code: [null, Validators.required],
      companyId: [null, Validators.required],
      remark: [null,],
      UserSelectedValue: [null]
    });
    this.form3 = fb.group({
      //companyId: [null, Validators.required],
      department: [null, Validators.required],
      userId: [null, Validators.required],
      divideType: ['PROFITS_DIVIDE', Validators.required],
      num: [null],
      amount: [null],
      proportion: [0],
    });

    this.formRenewType = fb.group({
      renewType: [null, Validators.required],
      renewFlag: [null, Validators.required],
    });
  }

  toPayment(id: string, orderAccount: string) {
    this.modalService.create({
      nzTitle: '发起订单支付',
      nzContent: ModalPaymentComponent,
      nzComponentParams: {
        id: id,
        orderAccount: orderAccount
      },
      nzStyle: { top: '40px' },
      nzWidth: 1100,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
  }

  // 切换菜单
  changeTabs(e) {
    // 已经激活菜单，不再请求数据
    if (this.activeList.includes(e)) {
      return
    }

    this.activeList.push(e);
    switch (e) {
      case 1:
        this.getOrderProduct();
        break;
      case 2:
        this.getPaymentList();
        break;
      case 3:
        this.getRefundRecord();
        break;
      case 4:
        this.getContractDetail();
        break;
      case 5:
        this.getInvoicebyOrder();
        break;
      case 6:
        this.getOrderRequesExam();
        this.getOrderRequesRefundExam();
        break;
      case 7:
        this.getDistributorsProperties();
        break;
      case 8:
        this.getOrderRecordList();
        break;
      default:
        break;
    }
  }
  OrderTaskId;
  changeTaskList(obj) {
    this.OrderTaskId = obj.taskId;
    this.getTaskDetail(this.OrderTaskId);
    this.getTaskRecordList(this.OrderTaskId);
    this.getTaskContinueListById(this.OrderTaskId);
  }
  // #region tab1: 产品信息
  // 获取产品信息
  getOrderProduct() {
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.productInfo = res['data'];
      this.productInfo.map(i => {
        if (i.taskId != null) {
          this.getTaskDetail(i.taskId);
        }
      });
    })
  }

  // 获取任务详情
  pageCtrl = {
    pageIndex: 1,
    pageSize: 5
  }

  loadingMore = true;
  getTaskDetail(id) {
    this.api.getTaskDetail({ taskId: id }).subscribe(res => {
      this.taskInfo = res['data'];
    })
  }
  //跟进记录
  getTaskRecordList(id) {
    const params = {
      current: this.pageCtrl.pageIndex,
      size: this.pageCtrl.pageSize,
      object: {
        taskId: id

      }
    }
    this.api.getTaskRecordList(params).subscribe(res => {
      this.taskRecordList = [...this.taskRecordList, ...res['data'].records];
      if (this.pageCtrl.pageIndex == res['data'].pages) {
        this.loadingMore = false;
      }
    })
  }
  // 是否显示加载更多
  onLoadMore() {
    this.pageCtrl.pageIndex++;
    this.getTaskRecordList(this.OrderTaskId);
  }

  //交付记录
  pageCtrl3 = {
    pageIndex: 1,
    pageSize: 5
  }
  getTaskContinueListById(id) {
    const params = {
      current: this.pageCtrl3.pageIndex,
      size: this.pageCtrl3.pageSize,
      object: {
        taskId: id
      }
    }
    this.api.getTaskContinueListById(params).subscribe(res => {
      this.customerTaskRecord = [...this.customerTaskRecord, ...res['data'].records];
      if (this.pageCtrl3.pageIndex == res['data'].pages) {
        this.customerLoadingMore = false;
      }
    })
  }
  customerLoadingMore = true;
  // 是否显示加载更多
  onCustomerLoadMore() {
    this.pageCtrl3.pageIndex++;
    this.getTaskContinueListById(this.OrderTaskId);
  }

  // 新建工单
  createTask(id, customerId, commissionPrice, productNature, p) {
    const modal = this.modalService.create({
      nzTitle: '新建工单',
      nzContent: ModalAddtaskComponent,
      nzComponentParams: {
        id: id,
        customerId: customerId,
        commissionPrice: commissionPrice,
        productNature: productNature,
        detail: p,
      },
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.getOrderProduct();
        let id = data['taskId'];
        console.log(id)
        this.getBsOrderTaskList();//关联工单
      }
    });
  }
  // 创建服务
  createServer(p) {
    const modal = this.modalService.create({
      nzTitle: '创建服务',
      nzContent: ModalAddserviceComponent,
      nzComponentParams: {
        detailInfo: p,
      },
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        //this.getOrderProduct();
        let id = data['taskId'];
        this.getOrderProduct();//任务详情
      }
    });
  }
  // 续费任务
  openTaskModal(id, orderId, content) {
    this.api.getParentTaskList({ productId: id }).subscribe(res => {
      this.taskList = res['data'];
    })

    this.modalService.create({
      nzTitle: '选择负责人',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 500,
      nzMaskClosable: false,
      nzOnCancel: () => this.addTaskForm.reset(),
      nzOnOk: () => {
        Object.keys(this.addTaskForm.controls).map((key) => {
          this.addTaskForm.controls[key].markAsDirty();
          this.addTaskForm.controls[key].updateValueAndValidity();
        });
        if (!this.addTaskForm.valid) {
          return false;
        }
        this.addTaskForm.value.productId = id;
        this.addTaskForm.value.orderId = orderId;
        this.api.addRenewTask(this.addTaskForm.value).subscribe((res) => {
          this.message.success('添加成功！');
          this.addTaskForm.reset();
        })
      }
    });
  }
  // 审核模块 start
  userId;
  userKjId;
  usersList;
  getUserList(): void {
    this.api.getUserList().subscribe(res => {
      this.usersList = res['data']
    })
  }
  flowListStep;
  // 获取审核流程
  getServiceFlowList(serviceNo) {
    this.api.getServiceFlowList(serviceNo).subscribe(res => {
      this.flowListStep = res['data'];
      (this.flowListStep || []).map((item, index) => {
        if (item.stepNo == "superior") {
          item.userId = [];
          (item.flowBusinessStepUserList || []).map((child, index) => {
            item.userId.push(child.userId)
          })
        }
      })
    })
  }
  // 审核模块 end
  //发起退款申请
  payOnline = false; // 0 false 线下， 1 true 线上
  payYear = false;
  payAmountRefund = 0;
  createRefundModal(refundContent, id, online, payAmount) {
    this.payYear = false;
    this.payOnline = online;
    // 判断线上 是否超过一年
    if (online) {
      this.api.payOverYear(this.value).subscribe(res => {
        if (res['data'] == 1) {
          this.payOnline = false;
          this.payYear = true;
        }
      })
    }

    // 审核模块 start
    this.getUserList();
    this.getServiceFlowList('refund');
    // 审核模块 end
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.refundpProduct = res['data'];
      this.refundpProduct.map((item, index) => {
        item.refundsAmount = item.paymentAmount;
      })
      this.form1.get('refundIsAll').setValue('0');
      this.form1.get('refundCount').setValue(payAmount);
      this.payAmountRefund = payAmount;
      console.log(this.form1.value);
      this.labelModal = this.modalService.create({
        nzTitle: '发起订单退款',
        nzContent: refundContent,
        nzStyle: { top: '40px' },
        nzWidth: 700,
        nzMaskStyle: {
          'background-color': 'rgb(245, 246, 249)',
        },
        nzMaskClosable: false,
        nzOnCancel: () => {
          this.form1.reset();
          this.form1.get('refundIsAll').setValue('0');
          this.form1.get('refundCount').setValue(payAmount);
        },
        nzOnOk: () => {

          if (!this.payOnline || this.form1.get('refundsMethodId').value) {
            Object.keys(this.form1.controls).map((key) => {
              this.form1.controls[key].markAsDirty();
              this.form1.controls[key].updateValueAndValidity();
            });
            if (!this.form1.valid) {
              return false;
            }

          }
          this.addRefund(id);
        }
      });
    })

  }
  createRefundModalYsk(refundContent, id, online, payAmount) {
    this.payOnline = online;
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.refundpProduct = res['data'];
      this.form1.get('refundIsAll').setValue('0');
      this.form1.get('refundCount').setValue(payAmount);
      this.payAmountRefund = payAmount;
      console.log(this.form1.value);
      this.labelModal = this.modalService.create({
        nzTitle: '发起转单',
        nzContent: refundContent,
        nzStyle: { top: '40px' },
        nzWidth: 700,
        nzMaskStyle: {
          'background-color': 'rgb(245, 246, 249)',
        },
        nzMaskClosable: false,
        nzOnCancel: () => {
          this.form1.reset();
          this.form1.get('refundIsAll').setValue('0');
          this.form1.get('refundCount').setValue(payAmount);
        },
        nzOnOk: () => {
          // if (!this.payOnline) {
          //   Object.keys(this.form1.controls).map((key) => {
          //     this.form1.controls[key].markAsDirty();
          //     this.form1.controls[key].updateValueAndValidity();
          //   });
          //   if (!this.form1.valid) {
          //     return false;
          //   }
          // }
          if (!this.form1.get('refundOtherReson').value) {
            this.message.error("退款原因不能为空！");
            return false;
          }
          if (!this.form1.get('refundCount').value) {
            this.message.error("请输入退款金额！");
            return false;
          }
          this.addRefundYsk(id);
        }
      });
    })

  }
  // 修改付款公司 type 0 付款公司， 1 财务审核到账日期
  createType = 0;
  createCompany(content, id, type) {
    this.createType = type;
    this.labelModal = this.modalService.create({
      nzTitle: '修改',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formFk.reset();
      },
      nzOnOk: () => {
        // Object.keys(this.formFk.controls).map((key) => {
        //   this.formFk.controls[key].markAsDirty();
        //   this.formFk.controls[key].updateValueAndValidity();
        // });
        // if (!this.formFk.valid) {
        //   return false;
        // }
        let data = {
          id: id,
        }
        if (type == 0) {
          this.formFk.controls['paymentCompany'].markAsDirty();
          this.formFk.controls['paymentCompany'].updateValueAndValidity();
          if (!this.formFk.get('paymentCompany').value) {
            return false;
          }
          data['paymentCompany'] = this.formFk.get('paymentCompany').value;
        } else if (type == 1) {
          this.formFk.controls['paymentTime'].markAsDirty();
          this.formFk.controls['paymentTime'].updateValueAndValidity();
          if (!this.formFk.get('paymentTime').value) {
            return false;
          }
          data['paymentTime'] = Utils.dateFormat(this.formFk.get('paymentTime').value, 'yyyy-MM-dd hh:mm:ss');
        } else if (type == 2) {
          this.formFk.controls['bankFlowNo'].markAsDirty();
          this.formFk.controls['bankFlowNo'].updateValueAndValidity();
          if (!this.formFk.get('bankFlowNo').value) {
            return false;
          }
          data['bankFlowNo'] = this.formFk.get('bankFlowNo').value;
        }
        this.api.payUpdateById(data).subscribe(res => {
          this.message.success('修改成功');
          this.formFk.reset();
          this.getPaymentList();
        })
      }
    });
  }
  // 修改产品单位
  editProductNature(content, id) {
    this.labelModal = this.modalService.create({
      nzTitle: '修改',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formPN.reset();
      },
      nzOnOk: () => {
        Object.keys(this.formPN.controls).map((key) => {
          this.formPN.controls[key].markAsDirty();
          this.formPN.controls[key].updateValueAndValidity();
        });
        if (!this.formPN.valid) {
          return false;
        }
        let data = {
          id: id,
          orderId: this.value,
          productNature: this.formPN.get('productNature').value,
        }
        this.api.updateProductNature(data).subscribe(res => {
          this.message.success('修改成功');
          this.formPN.reset();
          this.getOrderProduct();
        })
      }
    });
  }
  // 修改产品成本
  editProductCost(content, id) {
    this.labelModal = this.modalService.create({
      nzTitle: '修改成本',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formCost.reset();
      },
      nzOnOk: () => {
        Object.keys(this.formCost.controls).map((key) => {
          this.formCost.controls[key].markAsDirty();
          this.formCost.controls[key].updateValueAndValidity();
        });
        if (!this.formCost.valid) {
          return false;
        }
        let data = {
          id: id,
          cost: this.formCost.get('cost').value,
        }
        this.api.updateProductCost(data).subscribe(res => {
          this.message.success('修改成功');
          this.formCost.reset();
          this.getOrderProduct();
        })
      }
    });
  }
  bussCompanyChange(e) {
    this.bussCompanyList.map(item => {
      if (item.signedEnterpriseId == e) {
        this.formRz.get('bussCompanyName').setValue(item.fullNameOffice);
      }
    })
  }
  // 修改入账公司
  editSignedEnterprise(content) {
    this.getAllCompany();
    this.labelModal = this.modalService.create({
      nzTitle: '修改',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formRz.reset();
      },
      nzOnOk: () => {
        Object.keys(this.formRz.controls).map((key) => {
          this.formRz.controls[key].markAsDirty();
          this.formRz.controls[key].updateValueAndValidity();
        });
        if (!this.formRz.valid) {
          return false;
        }
        let data = {
          id: this.value,
          bussCompanyId: this.formRz.get('bussCompanyId').value,
          bussCompanyName: this.formRz.get('bussCompanyName').value,
        }
        this.api.updateBussCompanyList(data).subscribe(res => {
          this.message.success('修改成功');
          this.formRz.reset();
          this.getOrderDetail();
        })
      }
    });
  }

  // 获取公司列表
  companyList = [];//公司列表
  getCompanyList() {
    this.api.getCompanyList({ id: this.orderInfo.customerId }).subscribe(res => {
      this.companyList = res['data'];
    })
  }
  // 修改产品公司
  editProductCompany(content, id) {
    this.getCompanyList();
    this.labelModal = this.modalService.create({
      nzTitle: '添加公司',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formProductCompany.reset();
      },
      nzOnOk: () => {
        Object.keys(this.formProductCompany.controls).map((key) => {
          this.formProductCompany.controls[key].markAsDirty();
          this.formProductCompany.controls[key].updateValueAndValidity();
        });
        if (!this.formProductCompany.valid) {
          return false;
        }
        let data = {
          id: id,
          orderId: this.value,
          companyId: this.formProductCompany.get('companyId').value,
        }
        this.api.updateProductCompanyId(data).subscribe(res => {
          this.message.success('修改成功');
          this.formRz.reset();
          this.getOrderProduct();
        })
      }
    });
  }

  editOrderModal(updateContent, id) {
    this.api.getOrderOrder({ id: id }).subscribe(res => {
      let data = res['data'];
      Object.keys(data).map((key) => {
        if (this.form2.get(key)) {
          this.form2.get(key).setValue(data[key]);
        }
      })
      this.discountsAmount = res['data'].discountsAmount
    })

    this.labelModal = this.modalService.create({
      nzTitle: '订单编辑',
      nzContent: updateContent,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnOk: () => this.updateOrder(id, 0)
    });
  }

  // 金额产品列表
  productList = [];
  productTotalPayment = 0; //产品合计金额（元）
  allTotalPayment = 0; //订单金额（元）
  discountsAmount = 0; //优惠价格（元）
  // 编辑金额
  priceAoumtModal(updateContent, id) {
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.productList = res['data'];
      this.getPriceTotal();
    })
    this.labelModal = this.modalService.create({
      nzTitle: '编辑金额',
      nzContent: updateContent,
      nzStyle: { top: '40px' },
      nzWidth: 1000,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnOk: () => this.updateOrder(id, 1)
    });
  };
  // 更改订单
  editPriceCount() {
    this.form2.get('totalPayment').setValue(this.allTotalPayment);
  };
  // 产品金额操作逻辑
  getPriceTotal() {
    this.productTotalPayment = 0;
    this.productList.map(item => {
      this.productTotalPayment += Number(item.salePrice);
    })
    this.allTotalPayment = this.productTotalPayment - this.discountsAmount
  };

  itemAlert = false;
  productNmae: string;
  getItemRealPrice(data) {
    if (data.salePrice < data.productPrice) {
      this.itemAlert = true;
      this.productNmae = '当前产品：' + "(" + data.productName + ")" + ' 实际销售价 已低于 市场指导价，请与上级领导核实';
    }
    this.getPriceTotal();
  }

  afterClose(): void {
    console.log('close');
    this.itemAlert = false;
  }

  //新增菜单
  // type 0修改表单 1 未修改金额
  updateOrder(id, type) {
    // var that = this;
    // var promise = new Promise((resolve, reject) => {
    //   setTimeout(()=>{
    //     if (!that.itemAlert) {
    //       resolve('成功');
    //     } else {
    //       reject('失败');
    //     }
    //   },0);
    // })
    // promise.then(function(value){
    //   console.log(value)
    // },function(){
    //   that.message.warning('请仔细阅读提示信息，关闭后在进行提交订单',{
    //     nzDuration: 5000
    //   });
    //   return ;
    // })

    Object.keys(this.form2.controls).map((key) => {
      this.form2.controls[key].markAsDirty();
      this.form2.controls[key].updateValueAndValidity();
    });
    let params = {};
    if (type == 1) {
      params['orderId'] = id;
      this.productList.map((item, index) => {
        item.realPrice = item.salePrice;

        if (item.salePrice < item.productPrice) {
          params['priceStatus'] = 4;
          return
        } else {
          params['priceStatus'] = 1;
        }
      })

      params['productList'] = this.productList;
      params['discountsAmount'] = this.discountsAmount;
      params['totalPayment'] = this.allTotalPayment;
    } else {
      params = this.form2.value;
      params['orderId'] = id;
    }
    this.api.updateOrder(params).subscribe(res => {
      this.itemAlert = false;
      this.message.success('修改成功');
      //this.drawerRef.close();
      if (type == 0) {
        //location.reload();
      } else if (type == 1) {
        this.form2.get('totalPayment').setValue(this.allTotalPayment);
      }
    })
  }
  // 退款产品列表
  refundpProduct = [];
  getRefundpProduct() {
    this.api.getOrderProduct({ orderId: this.value }).subscribe(res => {
      this.refundpProduct = res['data'];
    })
  }
  getRefundPrice(data) {
    console.log(data)
    if ((data.paymentAmount || 0) < data.refundsAmount) {
      this.message.error("退款金额不可大于已支付金额！");
      return false;
    }
    this.getRefundPriceTotal();
  }
  getRefundPriceTotal() {
    if (this.form1.get('refundIsAll').value == 1) {
      // 部分退款
      var refundCount = 0;
      this.refundpProduct.map(item => {
        refundCount += Number(item.refundsAmount || 0);
      })
      this.form1.get('refundCount').setValue(refundCount);
    } else {
      // 全部退款  退款金额必须等于产品的金额
      // var refundCount = 0;
      // this.refundpProduct.map(item => {
      //   refundCount += Number(item.refundsAmount || 0);
      // })
      // if (refundCount != this.form1.get('refundCount').value) {
      //   this.message.error("产品合计退款金额必须等于总退款金额！");
      //   return false;
      // }
    }

  };
  refundChange(e) {
    if (e == 0) {
      this.form1.get('refundCount').setValue(this.payAmountRefund);
      this.refundpProduct.map((item, index) => {
        item.refundsAmount = item.paymentAmount;
      })
    } else {
      this.getRefundPriceTotal();
    }
  }

  addRefund(id) {
    let data = {
      payId: id,
      refundOtherReson: this.form1.get('refundOtherReson').value,

      receivingSide: this.form1.get('receivingSide').value,
      receivingAccount: this.form1.get('receivingAccount').value,
      receivingBank: this.form1.get('receivingBank').value,

      refundIsAll: this.form1.get('refundIsAll').value,
      refundCount: this.form1.get('refundCount').value,
    }
    if (!this.payOnline || this.form1.get('refundsMethodId').value) {
      data['refundsMethodId'] = 6;
    }
    // 判断产品退款金额与支付金额对比
    var sysOrderRefundsProductList = [];
    var isRedfund = true;
    this.refundpProduct.map((item, index) => {
      if ((item.paymentAmount || 0) < (item.refundsAmount || 0)) {
        isRedfund = false;
        return false;
      }
      if (item.refundsAmount > 0) {
        sysOrderRefundsProductList.push({
          orderProductId: item.id,
          refundsAmount: item.refundsAmount
        })
      }
    })
    if (!isRedfund) {
      this.message.error("退款金额不可大于已支付金额！");
      return false;
    }
    if (data.refundIsAll == 0) {
      // 全部退款  退款金额必须等于产品的金额
      var refundCount = 0;
      this.refundpProduct.map(item => {
        refundCount += Number(item.refundsAmount || 0);
      })
      if (refundCount != this.form1.get('refundCount').value) {
        this.message.error("产品合计退款金额必须等于总退款金额！");
        return false;
      }
    }
    data['sysOrderRefundsProductList'] = sysOrderRefundsProductList;
    // 审核模块 start
    var flowBusinessStepDTOList = [];
    var stepBool = true;
    (this.flowListStep || []).map((item, index) => {
      flowBusinessStepDTOList[index] = {
        serviceId: item.serviceId,
        serviceName: item.serviceName,
        serviceNo: item.serviceNo,
        stepId: item.stepId,
        stepName: item.stepName,
        stepNo: item.stepNo,
      }
      // 循环用户列表，获取用户id 与名称
      var selected_user = [];
      var selectedFin = [];
      // 区分用户为多人或者单人
      if (item.userId instanceof Array) {
        if (item.userId.length == 0) {
          stepBool = false;
          return false;
        }
        item.userId.map((child, index) => {
          selected_user = this.usersList.filter((obj) => {
            obj.userName = obj.name;
            return obj.userId == child
          });
          selectedFin.push(Object.assign({}, ...selected_user))
        })
      } else {
        if (!item.userId) {
          stepBool = false;
          return false;
        }
        selectedFin = this.usersList.filter((obj) => {
          obj.userName = obj.name;
          return obj.userId == item.userId
        });
      }
      flowBusinessStepDTOList[index]['flowBusinessStepUserList'] = selectedFin;
    })
    if (!stepBool) {
      this.message.error('审核人员不能为空！');
      this.canSubmit = false;
      return;
    }
    data['flowBusinessStepDTOList'] = flowBusinessStepDTOList;
    // 审核模块 end
    this.api.addOrderRefundsVerify(data).subscribe(res => {
      this.message.success('发起退款成功');

      this.getPaymentList();
      this.getRefundRecord();
    })
  }
  addRefundYsk(id) {
    let data = {
      payId: id,
      refundOtherReson: this.form1.get('refundOtherReson').value,
      refundIsAll: this.form1.get('refundIsAll').value,
      refundCount: this.form1.get('refundCount').value,
    }
    // 判断产品退款金额与支付金额对比
    var sysOrderRefundsProductList = [];
    var isRedfund = true;
    this.refundpProduct.map((item, index) => {
      if ((item.paymentAmount || 0) < (item.refundsAmount || 0)) {
        isRedfund = false;
        return false;
      }
      if (item.refundsAmount > 0) {
        sysOrderRefundsProductList.push({
          orderProductId: item.id,
          refundsAmount: item.refundsAmount
        })
      }
    })
    if (!isRedfund) {
      this.message.error("退款金额不可大于已支付金额！");
      return false;
    }
    if (data.refundIsAll == 0) {
      // 全部退款  退款金额必须等于产品的金额
      var refundCount = 0;
      this.refundpProduct.map(item => {
        refundCount += Number(item.refundsAmount || 0);
      })
      if (refundCount != this.form1.get('refundCount').value) {
        this.message.error("产品合计退款金额必须等于总退款金额！");
        return false;
      }
    }
    data['sysOrderRefundsProductList'] = sysOrderRefundsProductList;
    console.log(data);
    this.api.addOrderRefundsVerifyYsk(data).subscribe(res => {
      this.message.success('发起退款成功，请到预收款订单查看');
      this.getPaymentList();
      this.getRefundRecord();
    })
  }
  // #endregion

  // #region tab2: 支付记录
  // 获取支付记录
  getPaymentList() {
    this.api.getPaymentList({ orderId: this.value }).subscribe(res => {
      this.paymentList = res['data'];
      (this.paymentList || []).map((item, index) => {
        let id = item.verifyPerson
        let manageUserId = item.manageUserId;
        if (id) {
          this.api.getSysUserInfos(id).subscribe(res => {
            this.paymentList[index].companyName = res['data'].companyName
            this.paymentList[index].deptName = res['data'].deptName
          })
        }

        if (manageUserId) {
          this.api.getSysUserInfos(manageUserId).subscribe(res => {
            this.paymentList[index].manageUsercompanyName = res['data'].companyName
            this.paymentList[index].manageUserdeptName = res['data'].deptName
          })
        }

      })
    })
    return
  }

  // 上传支付凭证
  UploadRecord(info, list) {
    console.log(list)
    if (info.file.status === 'done' && info.file.response.code === 200) {
      list.push({ fileUrl: info.file.response.data });
    }
  }
  // #endregion

  // #region tab3: 退款记录
  // 获取退款信息
  getRefundRecord() {
    this.api.getRefundRecord({ orderId: this.value }).subscribe(res => {
      this.refundRecord = res['data'];

      (this.refundRecord || []).map((item) => {
        let arr = item.refundApproveLogs || [];
        // 适应旧数据
        if (arr.length > 0) {
          for (let i = 0; i < arr.length; i++) {
            let id = arr[i].approveUserId
            if (id) {
              this.api.getSysUserInfos(id).subscribe(res => {
                arr[i].approveCompanyName = res['data'].companyName
                arr[i].approveDeptName = res['data'].deptName
              })
            }
          }
        }
        //提成结算审核人ID 查询 审核人公司，部门，人
        if (item.flowBusinessStepDTOList && item.flowBusinessStepDTOList.length > 0) {
          item.flowBusinessStepDTOList.map((item, index) => {
            (item.flowBusinessStepUserList || []).map((child, index) => {
              this.api.getSysUserInfos(child.userId).subscribe(res => {
                child['checkUserCompanyName'] = res['data'].companyName;
                child['checkUserDeptName'] = res['data'].deptName;
              })
            })

          })
        }
      })

    })
  }
  // #endregion

  // #region tab4: 合同详情
  getContractDetail() {
    this.api.getContractDetail({ orderId: this.value }).subscribe(res => {
      this.contractDetail = res['data'];
      console.log(this.contractDetail)
      if (this.contractDetail) {
        var id = this.contractDetail.approveId;
        if (id) {
          this.api.getSysUserInfos(id).subscribe(res => {
            this.contractDetail.approveCompanyName = res['data'].companyName
            this.contractDetail.approveDeptName = res['data'].deptName
          })
        }
        if (this.contractDetail.flowBusinessStepDTOList && this.contractDetail.flowBusinessStepDTOList.length > 0) {
          this.contractDetail.flowBusinessStepDTOList.map((item, index) => {
            (item.flowBusinessStepUserList || []).map((child, index) => {
              this.api.getSysUserInfos(child.userId).subscribe(res => {
                child['checkUserCompanyName'] = res['data'].companyName;
                child['checkUserDeptName'] = res['data'].deptName;
              })
            })

          })
        }
      }
    })
  }
  // #endregion

  // #region tab5: 发票记录
  getInvoicebyOrder() {
    this.api.getInvoicebyOrder({ orderId: this.value }).subscribe(res => {
      this.inoviceList = res['data'];

      (this.inoviceList || []).map((item, index) => {
        let id = item.approveId
        if (id) {
          this.api.getSysUserInfos(id).subscribe(res => {
            this.inoviceList[index].approveCompanyName = res['data'].companyName
            this.inoviceList[index].approveDeptName = res['data'].deptName
          })
        }
      })

    })
  }
  // 上传支付凭证
  UploadInvoice(info, list) {
    if (info.file.status === 'done' && info.file.response.code === 200) {
      list.push({ attachmentUrl: info.file.response.data });
    }
  }

  // 获取订单详情
  getOrderDetail() {
    this.api.getOrderDetail({ orderId: this.value }).subscribe(res => {
      this.orderInfo = res['data'];
      this.jobId = this.orderInfo.jobId;
      this.payStatus = this.orderInfo.payStatus;
      // this.getDistributorsProperties();
      this.getBsOrderTaskList();
    })
  }
  bsOrderTaskList = [];
  // 获取关联工单
  getBsOrderTaskList() {
    this.api.getBsOrderTaskList(this.value).subscribe(res => {
      this.bsOrderTaskList = res['data'];
    })
  }
  // 打开任务详情
  open(id, index) {
    const modal = this.drawerService.create<DrawerTaskComponent, { value: string, index: string, noButton: string }, string>({
      nzWidth: 700,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerTaskComponent,
      nzContentParams: {
        value: id,
        index: index,
        noButton: '0'
      }
    });
  }
  // #endregion
  // 打开请款详情
  openRequest(id, editType, serviceProviderId, requestNo) {
    const modal = this.drawerService.create<DrawerRequesExamineComponent, { value: string, editType: string, serviceProviderId: string, requestNo: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerRequesExamineComponent,
      nzContentParams: {
        value: id,
        editType: editType,
        serviceProviderId: serviceProviderId,
        requestNo: requestNo
      }
    });
  }
  imgShowBlock(url, type) {
    var urls;
    if (type == 'invoice') {
      urls = {
        fileUrl: url
      }
    } else {
      urls = url
    }
    // window.open(url.fileUrl);
    const modal = this.modalService.create({
      nzWidth: 'max-content',
      nzStyle: { top: '40px' },
      nzContent: ModalOpenImageComponent,
      nzComponentParams: {
        content: urls,
      },
      nzFooter: null,
      nzTitle: null,
      nzClosable: false,
    });
  }

  //删除支付记录
  deleteRecord(data) {
    this.api.deletePayRecord({ payRecordId: data }).subscribe(res => {
      this.message.success('删除支付记录成功');
      this.getPaymentList();
    })
  }

  cancelPayment(id) {
    this.api.tCustomerCancelPayOrder({ payRecordId: id }).subscribe(res => {
      this.message.success('支付取消成功');
      this.getPaymentList();
    })
  }
  ewmImg = null;
  imgShow(src, ewmShowContent) {
    this.ewmImg = src;
    const modal = this.modalService.create({
      nzWidth: 'max-content',
      nzStyle: { top: '40px' },
      nzContent: ewmShowContent,
      nzComponentParams: {
        content: src,
      },
      nzFooter: null,
      nzTitle: null,
    });
  }

  technologicalShow(line, technologicalShowContent) {
    let src;
    var title;
    this.ewmImg = 'https://duoyoumi-1251277880.cos.ap-guangzhou.myqcloud.com/images/20210918/1631943754907.png'
    title = '审核流程'
    const modal = this.modalService.create({
      nzWidth: 'max-content',
      nzStyle: { top: '40px' },
      nzContent: technologicalShowContent,
      nzComponentParams: {
        content: src,
      },
      nzFooter: null,
      nzTitle: title,
    });
  }

  // 添加跟进记录
  openEditor() {
    const modal = this.modalService.create({
      nzTitle: '添加跟进记录',
      nzContent: ModalWangeditorComponent,
      nzComponentParams: {
        id: this.value,
        type: 'order',
        bindId: this.orderInfo.id,
        bindName: this.orderInfo.orderNo,
        customerId: this.orderInfo.customerId,
        customerName: this.orderInfo.customerName,
      },
      nzStyle: { top: '40px' },
      nzWidth: 800,
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.api.saveUserCustomerLog(data).subscribe(res => {
          this.message.success('添加成功');
          this.orderRecordList = [];
          this.getOrderRecordList();
        })
      }
    });
  }

  pageCtrl2 = {
    pageIndex: 1,
    pageSize: 5
  }
  loadingMoreLog = true;
  getOrderRecordList() {
    const params = {
      current: this.pageCtrl2.pageIndex,
      size: this.pageCtrl2.pageSize,
      object: {
        bindId: this.value,
        logType: 2,
      }
    }
    this.api.getUserCustomerLogLists(params).subscribe(res => {
      this.orderRecordList = [...this.orderRecordList, ...res['data'].records];
      if (this.pageCtrl2.pageIndex == res['data'].pages) {
        this.loadingMoreLog = false;
      }
    })
  }
  // 请款记录
  equesExamList = [];
  getOrderRequesExam() {
    this.api.requestDetail(this.value).subscribe(res => {
      this.equesExamList = res['data'];
    })
  }
  // 请款退回记录
  equesExamRefundList = [];
  getOrderRequesRefundExam() {
    this.api.requestRefundDetail(this.value).subscribe(res => {
      this.equesExamRefundList = res['data'];
    })
  }
  // 是否显示加载更多
  onLoadMoreLog() {
    this.pageCtrl2.pageIndex++;
    this.getOrderRecordList();
  }
  // 预览凭证
  preview(data) {
    window.open(data);
  }
  // //获取联合签单人列表
  relevanceUser() {
    this.api.relevanceUser(this.value).subscribe(res => {
      this.addJobJointList = res['data'];
    });
  }
  // //选择联合签单人
  addJobJoint(content) {
    //this.form3.reset()
    // this.getCompanyData();
    // this.findDeptById();
    this.getUserList();
    this.relevanceUser();
    const modalService = this.modalService.create({
      nzTitle: '选择联合签单人',
      nzContent: content,
      nzStyle: { top: '80px' },
      nzWidth: 900,
      nzMaskClosable: false,
      nzFooter: null,
      nzOnCancel: () => {
        this.modalService.closeAll();
      },
      nzOnOk: () => {
        this.addUnionUserOrder();
      }
    });
  }

  formatterPercent = (value: number) => `${value} %`;
  parserPercent = (value: string) => value.replace(' %', '');
  // 联合签单人列表
  addJobJointList = [];
  divide = 'PROFITS_DIVIDE';
  canSubmit = true;
  proportion = 0;
  divideTypeList = [
    { value: 'PROFITS_DIVIDE', name: '毛利分成' },
    // { value: 'PRICE', name: '一口价' },
  ]
  divideTypeChange(e) {
    if (e == 'PROFITS_DIVIDE') {
      this.divide = 'PROFITS_DIVIDE'
    } else {
      this.divide = 'PRICE'
    }
  }

  // //获取公司信息
  getCompanyData() {
    this.api.getSameAreaOfficeCompantt().subscribe(res => {
      this.companyData = res['data'];
    });
  }
  cancelUnion() {
    // this.form3.reset()
    this.modalService.closeAll()
  }
  ContactsI = 0;
  addCard(): void {
    this.addJobJointList = [
      ...this.addJobJointList,
      {
        userId: null,
        num: null,
        divideType: 'PROFITS_DIVIDE',
        userType: 1,
      }
    ];
    this.ContactsI++;
  }
  // 删除配置
  deleteRowCard(index: number): void {
    let newList = JSON.parse(JSON.stringify(this.addJobJointList));
    newList.splice(index, 1)
    this.addJobJointList = newList;
  }
  addUnionUserOrder() {
    this.canSubmit = false;
    // Object.keys(this.form3.controls).map((key) => {
    //   this.form3.controls[key].markAsDirty();
    //   this.form3.controls[key].updateValueAndValidity();
    // });
    // if (!this.form3.valid) {
    //   this.canSubmit = true
    //   return false;
    // }
    let salePriceAll = 0;
    (this.addJobJointList || []).map(item => {
      if (item.userId == null) {
        this.canSubmit = true;
        this.message.error('人员不能为空！');
        return false;
      }
      if (item.num == null) {
        this.canSubmit = true;
        this.message.error('请填写正确的分成比例！');
        return false;
      }
      salePriceAll += item.num;
    });
    if (this.canSubmit) {
      return false;
    }
    if (salePriceAll != 100) {
      this.canSubmit = true;
      this.message.error('分成比例合计需为100！');
      return
    }
    var data = {
      orderId: this.value,
      bsOrderRelevanceUserList: this.addJobJointList
    }
    this.api.saveRelevanceUser(data).subscribe(res => {
      this.canSubmit = true
      this.message.success('添加成功');
      //this.drawerRef.close();
      this.modalService.closeAll()
      this.getOrderDetail();
    });
    // var divideNum;

    // if (this.divide == 'PRICE') {
    //   if (this.form3.value.amount === null) {
    //     this.canSubmit = true
    //     this.message.error('分成金额不能为空');
    //     return
    //   } else {
    //     divideNum = this.form3.value.amount
    //   }
    // } else {
    //   divideNum = this.form3.value.proportion
    // }
    // let data = {
    //   orderId: this.value,
    //   userId: this.form3.value.userId,
    //   divideType: this.form3.value.divideType,
    //   num: divideNum,
    // }

    // this.api.addUnionUserOrder(data).subscribe(res => {
    //   this.canSubmit = true
    //   this.message.success('添加成功');
    //   //this.drawerRef.close();
    //   this.modalService.closeAll()
    //   this.getOrderDetail();
    // });
  }

  depid;
  onDeptChanges(values): void {
    if (values == null) {
      return;
    }
    if (values.length > 1) {
      this.depid = values.slice(-1);
      let data = {
        deptId: this.depid
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    } else {
      let data = {
        deptId: values
      }
      this.api.getRoleUserListRelevancy(data).subscribe(res => {
        this.staffList = res['data'];
      })
    }
  }

  UserSelectedChange(e) {
    //console.log(e)
  }
  deptList = []; // 用户权限，部门列表
  findDeptById() {
    let _companyId = JSON.parse(localStorage.getItem('userInfo'))
    if (null != _companyId) {
      this.api.findDeptByCompanyId({ companyId: _companyId.company['id'] }).subscribe((res) => {
        this.deptList = res['data'];
      })
    }
  }

  // #region tab 渠道商
  createPropertis(areaCateid, officeId) {
    const modal = this.modalService.create({
      nzWidth: 'max-content',
      nzStyle: { top: '40px' },
      nzContent: CreatePropertyDistributorsComponent,
      nzComponentParams: {
        areaCateid: areaCateid,
        officeId: officeId,
        orderId: this.orderInfo.id,
      },
      nzFooter: null,
      nzTitle: null,
      nzClosable: false,
    });
  }

  // #endregion
  contractNum = 0;

  // distributorsProperties = [{
  //   cateName: '',
  //   list: [{
  //     propertyName: '',
  //     price: '',
  //   }],
  // }];//附加提成
  distributorsProperties = [];
  getDistributorsProperties() {
    // 调整为提成申请
    this.api.getByOrderId(this.value).subscribe(res => {
      this.distributorsProperties = res['data'];
    })
    // this.api.getDistributorsPropertiesByCustomerId({ customerId: this.orderInfo.customerId }).subscribe(res => {
    //   this.distributorsProperties = res['data'];
    // })
  }
  // 打开详情
  openService(id) {
    const modal = this.drawerService.create<DrawerServiceStoragesComponent, { value: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#fff'
      },
      nzContent: DrawerServiceStoragesComponent,
      nzContentParams: {
        value: id
      }
    });
    modal.afterClose.subscribe(() => {
    })
  }

  // 编辑产品
  editProduct() {
    // menuType == my 我的订单isShare == 0 否则为甩单 isShare = 1
    var isShare = 0
    if (this.menuType != 'my') {
      isShare = 1;
    }
    const modal = this.modalService.create({
      nzTitle: '编辑产品',
      nzContent: ModalEditProductComponent,
      nzComponentParams: {
        orderId: this.value,
        isShare: isShare,
        orderInfo: this.orderInfo
      },
      nzStyle: { top: '40px' },
      nzWidth: 1200,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzFooter: null
    });
    modal.afterClose.subscribe((data) => {
      if (data) {
        this.getOrderProduct();
        this.getOrderDetail();
      }

    });
  }

  permissions = []; // 按钮权限数组
  ngOnInit() {
    this.global.activePermission.subscribe(Message => {
      this.permissions = Message;
    });
    this.getOrderDetail();
    this.contractNum = this.type;
    this.changeTabs(this.contractNum);
    // if (this.type == 4) {
    //   this.contractNum = this.type;
    //   this.getContractDetail();
    // }
    this.priceDisabled = this.priceStatus == 1 || this.priceStatus == 4 || this.priceStatus == 3 ? false : true;
  }

  //富文本图片 点击查看
  showImages(event) {
    if (event.target.nodeName == 'IMG') {
      window.open(event.target.currentSrc)
    }
  }

  orderPayList = [];
  totalPayment = 0;
  openOrderPayList(content, data): void {
    this.api.deductionProductList(data.id).subscribe(res => {
      this.orderPayList = res['data'];
      const modal = this.modalService.create({
        nzTitle: '订单抵扣金额明细',
        nzContent: content,
        nzStyle: { top: '40px' },
        nzWidth: 900,
        nzMaskStyle: {
          'background-color': 'rgba(245, 246, 249, 0)',
        },
        nzMaskClosable: true,
        nzFooter: null
      });
    })

  }
  // 修改发票 start
  orderInvoiceRecordList = [];
  uploadChangeFp(info) {
    var attachmentUrls = [];
    if (info.file.status == 'done' || info.file.status == 'removed' && info.file.response.code == 200) {
      (info.fileList || []).map((item, index) => {
        var params = { "proofImageId": '' };
        params.proofImageId = item.response.data.id
        attachmentUrls.push(params)
      })
      this.orderInvoiceRecordList = attachmentUrls;
    }
  }
  openDate;//开票日期
  uploadTem(temp, id) {
    this.modalService.create({
      nzTitle: '修改发票',
      nzContent: temp,
      nzStyle: { top: '30px' },
      nzWidth: 500,
      nzMaskClosable: false,
      nzCancelText: null,
      nzOnOk: () => {
        if (this.orderInvoiceRecordList.length == 0) {
          this.message.error('请上传发票');
          return false;
        }
        if (!this.openDate) {
          this.message.error('请选择开票日期！');
          return false;
        }
        this.api.updateInvoiceRecord({
          invoiceId: id,
          orderInvoiceRecordList: this.orderInvoiceRecordList,
          openDate: this.dataToStr(this.openDate)
        }).subscribe(res => {
          this.getInvoicebyOrder();
          this.message.success('修改成功！');
          this.orderInvoiceRecordList = [];
        })
      }
    });
  }

  deleteInovice(payId) {
    this.api.deleteInvoice(payId).subscribe(res=> {
      this.getInvoicebyOrder();  
    });
  }
  
  redInovice(redInoviceTem,item) {
    this.modalService.create({
      nzTitle: '红冲发票信息',
      nzContent: redInoviceTem,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.redInoviceForm.reset();
      },
      nzOnOk: () => {
        // 确认按钮的回调函数
        if(!this.redInoviceForm.valid) {
          return false;
        }

        this.redInoviceForm.value.id = item.id;
        this.redInoviceForm.value.status = 2;
        this.api.updateInvoice(this.redInoviceForm.value).subscribe(res=> {
          this.getInvoicebyOrder();  
        });
      }
    });
  
  }

  editInovice(editInoviceTem,item) {

    console.log(item);
    // 初始化发票数据
    this.editInoviceForm.get("invoiceContent").setValue(item.invoiceContent);
    this.editInoviceForm.get("invoiceTaxNo").setValue(item.invoiceTaxNo);
    this.editInoviceForm.get("invoiceType").setValue(item.invoiceType?1:0);
    this.editInoviceForm.get("vatCompanyAddress").setValue(item.vatCompanyAddress);
    this.editInoviceForm.get("vatBankAccount").setValue(item.vatBankAccount);
    this.editInoviceForm.get("mailing").setValue(item.isMailing);
    this.editInoviceForm.get("consigneeRealname").setValue(item.consigneeRealname);
    this.editInoviceForm.get("consigneeTelphone").setValue(item.consigneeTelphone);
    this.editInoviceForm.get("consigneeAddress").setValue(item.consigneeAddress);
    this.invoiceType(item.invoiceType?1:0);
    this.mailingChange(item.isMailing);

    this.modalService.create({
      nzTitle: '修改发票信息',
      nzContent: editInoviceTem,
      nzStyle: { top: '40px' },
      nzWidth: 900,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.editInoviceForm.reset();
      },
      nzOnOk: () => {
        // 在提交前标记所有控件为Touched
        this.editInoviceForm.markAllAsTouched();
        // 确认按钮的回调函数
        if(!this.editInoviceForm.valid) {
          return false;
        }
        this.editInoviceForm.value.id = item.id;
        this.api.updateInvoice(this.editInoviceForm.value).subscribe(res=> {
          this.getInvoicebyOrder();  
        });
      }
    });
  }

  mailingChange(event): void {
    if (event == 1) {
      this.expressage = true;
    } else {
      this.expressage = false;
    }
  }

  invoiceType(event): void {
    if (event == 0) {
      this.dedicated = true;
    } else {
      this.dedicated = false;
    }
  }

  dataToStr(data) {
    var date = data;
    if (!date || !date.getFullYear) {
      return date;
    }
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
  }
  // 修改发票 end
  //获取公司信息
  bussCompanyList = [];
  getAllCompany() {
    this.api.getUserReimbursementsApplyCompanies().subscribe(res => {
      this.bussCompanyList = res['data'];
    })
  }

  // 状态
  renewFlagList = [
    { id: 0, name: '新签' },
    { id: 1, name: '续费' },
  ]
  // 续费
  renewTypeList = [
    { id: 1, name: '正常续费' },
    { id: 11, name: '提前续费' },
    { id: 2, name: '回签' },
  ]
  //新增
  renewTypeAddList = [
    { id: 3, name: '代账客户复购' },
    { id: 4, name: '代账客户转介绍' },
    { id: 5, name: '非代账客户复购' },
    { id: 6, name: '非代账客户转介绍' },
    { id: '7', name: '美团' },
    { id: '8', name: '高德' },
    { id: '9', name: '公司资源' },
    { id: 94, name: '内部介绍' },
    { id: 97, name: '自主开发' },
    { id: 96, name: '渠道开发' },
    { id: 98, name: '小红书' },
  ]
  getFindRenewFlag(e) {
    this.curRenewType['renewFlag'] = e;
    this.formRenewType.get('renewType').setValue('');
  }
  curRenewType;
  // 修改产品类型
  changeRenewType(content, item) {
    this.curRenewType = item;
    this.formRenewType.get('renewFlag').setValue(item.renewFlag);
    this.formRenewType.get('renewType').setValue(item.renewType);
    this.modalService.create({
      nzTitle: '修改产品类型',
      nzContent: content,
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskStyle: {
        'background-color': 'rgb(245, 246, 249)',
      },
      nzMaskClosable: false,
      nzOnCancel: () => {
        this.formFk.reset();
      },
      nzOnOk: () => {
        Object.keys(this.formRenewType.controls).map((key) => {
          this.formRenewType.controls[key].markAsDirty();
          this.formRenewType.controls[key].updateValueAndValidity();
        });
        if (!this.formRenewType.valid) {
          return false;
        }
        let params = this.formRenewType.getRawValue();
        params.id = item.id;
        this.api.updateProductType(params).subscribe(res => {
          this.message.success('修改成功');
          this.formRenewType.reset();
          this.getOrderProduct();
        })
      }
    });
  }
  //发起产品办结审核
  productFinish(id) {
    const params = {
      id: id,
      type: 'cpbj'
    };
    const modal = this.modalService.create({
      nzTitle: '产品办结审核流程',
      nzContent: ModalOrderExamineComponent,
      nzComponentParams: {
        params: params,
      },
      nzStyle: { top: '40px' },
      nzWidth: 700,
      nzMaskClosable: false,
      nzFooter: null,
    });
    modal.afterClose.subscribe(() => {
      this.getOrderProduct();
    })
  }

  // 打开详情
  openR(id, editType, serviceProviderId, requestNo) {
    const modal = this.drawerService.create<DrawerRequesExamineBackComponent, { value: string, editType: string, serviceProviderId: string, requestNo: string }, string>({
      nzWidth: 1000,
      nzWrapClassName: 'drawer-user',
      nzBodyStyle: {
        height: '100%',
        padding: 0,
        overflow: 'auto',
        background: '#f5f5ff'
      },
      nzContent: DrawerRequesExamineBackComponent,
      nzContentParams: {
        value: id,
        editType: editType,
        serviceProviderId: serviceProviderId,
        requestNo: requestNo
      }
    });
    modal.afterClose.subscribe(() => {
    })
  }

  payCodeOrder(payCode) {
    this.api.getPayRecordList(payCode).subscribe(res => {
      var orderInfo = res['data'];
      let component;
      component = DrawerOrderComponent;

      this.drawerService.create<DrawerOrderComponent, { value: string, type: any }, string>({
        nzWidth: 700,
        nzWrapClassName: 'drawer-user',
        nzBodyStyle: {
          height: '100%',
          padding: 0,
          overflow: 'auto',
          background: '#f5f5ff'
        },
        nzContent: component,
        nzContentParams: {
          value: orderInfo['id'],
          type: 2,
        }
      });
    })

  }
}
